import { React } from "react";
import { useState, useEffect } from 'react';
import {
  ChakraProvider,
  theme,
  Button,
  Stack,
  Text

} from '@chakra-ui/react';
import parse from 'html-react-parser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loading from "./Loading";
const URL = (props) => {
  const [html, setHtml] = useState('');
  const [state, setState] = useState('init');
  let queryUrl = window.location.pathname;

  const loadDataOnlyOnce = () => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');


    if (queryUrl && regex.test(queryUrl)) {
      setState('loading')
      fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/search', {
        method: 'POST',
        body: JSON.stringify({
          url: queryUrl.substring(1)
        }),
        headers: {
          'Content-type': 'application/json;',
        },
      }).then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Something went wrong');
      }

      )
        .then(response => {

          setHtml(response.html)
          setState('init')
          getResponse();

        }).catch((error) => {
          notify();
          setState('error')
        })
    }
    else {
      notify();
    }
  }

  // This function will called only once
  useEffect(() => {
   // loadDataOnlyOnce();
  }, [])
  const Undo = ({ onUndo, closeToast }) => {
    const onSuccess = () => {
      closeToast();
      fetch(' https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/feedback', {
        method: 'POST',
        body: JSON.stringify({
          url: queryUrl.substring(1),
          status: true
        }),
        headers: {
          'Content-type': 'application/json;',
        },
      }).then(response => {      
        closeToast();
        }

      )

    };

    const onFailure = () => {
      closeToast();
      fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/feedback', {
        method: 'POST',
        body: JSON.stringify({
          url: queryUrl.substring(1),
          status: false
        }),
        headers: {
          'Content-type': 'application/json;',
        },
      }).then(response => {      
        closeToast();
        }

      )
    };

    return (

      <div>
        <Text fontSize='md'>Paywall removed?</Text>
        <Stack direction='row' spacing={4} align='center'>
          <Button variant='link' size='sm' colorScheme='white' onClick={onSuccess}>Yes</Button>

          <Button variant='link' size='sm' colorScheme='white' onClick={onFailure}>No </Button>
        </Stack>
      </div>
    );
  };

  const notify = () => toast.error("Something Wrong!", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    theme: "colored",
    toastId: "customId"
  });

  const getResponse = () => toast.success(<Undo />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
    hideProgressBar: true,
    theme: "colored",
    closeOnClick: false,
    toastId: "customId1",

  });
  return (
    <ChakraProvider theme={theme}>
      {state === 'loading' && <Loading></Loading>}
      {state === 'init' && parse(html)}
      <ToastContainer />
    </ChakraProvider>
  );
};

export default URL;

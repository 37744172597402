import { React, useEffect } from "react";
import {
  useLocation
} from "react-router-dom"
import {
  Button,
  Stack,
  Text

} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import parse from 'html-react-parser'
const SearchResult = () => {
  const { state} = useLocation();

  useEffect(() => {
   // getResponse();
  }, [])
  const Undo = ({ onUndo, closeToast }) => {
    const onSuccess = () => {
      closeToast();
      fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/feedback', {
        method: 'POST',
        body: JSON.stringify({
          url: state.url,
          status: true
        }),
        headers: {
          'Content-type': 'application/json;',
        },
      }).then(response => {
        closeToast();
      }

      )

    };

    const onFailure = () => {
      closeToast();
      fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/feedback', {
        method: 'POST',
        body: JSON.stringify({
          url: state.url,
          status: false
        }),
        headers: {
          'Content-type': 'application/json;',
        },
      }).then(response => {
        closeToast();
      }

      )
    };

    return (

      <div>
        <Text fontSize='md'>Paywall removed?</Text>
        <Stack direction='row' spacing={4} align='center'>
          <Button variant='link' size='sm' colorScheme='white' onClick={onSuccess}>Yes</Button>

          <Button variant='link' size='sm' colorScheme='white' onClick={onFailure}>No </Button>
        </Stack>
      </div>
    );
  };


  const getResponse = () => toast.success(<Undo />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
    hideProgressBar: true,
    theme: "colored",
    closeOnClick: false,
    toastId: "customId1",

  });
  return (
    <div>

      <div>
        {parse(state.body)}
      </div>
      <ToastContainer />
    </div>
  );
};

export default SearchResult;

import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Link,
  Center,
  FormControl,
  Input,
  Divider,
  Tag,
  TagLabel,
  TagLeftIcon,
  HStack
} from '@chakra-ui/react';
import { FormEvent, ChangeEvent, useState } from 'react';
import {

  useNavigate
} from "react-router-dom"
import { CheckIcon } from '@chakra-ui/icons';
import {MdTrendingUp,MdSupervisorAccount} from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SupportedSites from './SupportedSites';
import AdsComponent from './components/AdsComponent';

export default function Home() {
  const navigate = useNavigate();

  const [url, setUrl] = useState('');
  const [state, setState] = useState(
    'initial'
  );
  const notify = () => toast.error("Something Wrong!", {
    position: toast.POSITION.TOP_CENTER,

    theme: "colored",
    toastId: "customId"
  });

  return (
    <Container maxW={'5xl'}>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}>
        <Heading
          fontWeight={800}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}>
          Explore Web  {' '}
          <Text as={'span'} color='#EF5D60'>
            shackle-free
          </Text>
        </Heading>
        <Text color={'black.500'} maxW={'3xl'} >

          Paste the url below
          <br />



        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          as={'form'}
          spacing={'12px'}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();

            setState('submitting');

            fetch('https://1ggwml0ftd.execute-api.us-east-1.amazonaws.com/v1/search', {
              method: 'POST',
              body: JSON.stringify({
                url: url
              }),
              headers: {
                'Content-type': 'application/json;',
              },
            }).then(response => {

              if (response.ok) {
                return response.json()
              }
              throw new Error('Something went wrong');
            }

            )
              .then(response => {
                setState('initial');
                navigate('/result', { state: { body: response.html, url: url } });
              })
              .catch((error) => {
                notify();
                setState('initial');
              })



          }}>
          <FormControl>
            <Input
              variant={'solid'}
              borderWidth={1}
              color={'gray.800'}
              _placeholder={{
                color: 'gray.400',
              }}

              id={'url'}
              type={'url'}
              required
              px={12}
              placeholder={'https://ny.com/article'}
              aria-label={'Your Email'}
              value={url}
              disabled={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setUrl(e.target.value)
              }
            />
          </FormControl>

            <FormControl w={{ base: '100%', md: '40%' }}>
              {/* <Button
             disabled={true}
                color={'white'}
                colorScheme='red' 
                rounded={'md'}
                px={12}
                isLoading={state === 'submitting'}

                type={state === 'success' ? 'button' : 'submit'}>
                {state === 'success' ? <CheckIcon /> : 'Read'}
              </Button> */}

            </FormControl>
       
          </Stack>
          <HStack spacing={4}>
          <Tag size='lg' colorScheme='gray' borderRadius='full'>
            <TagLeftIcon boxSize='24px' as={MdTrendingUp} />

  <TagLabel>Works with 150+ Websites</TagLabel>
</Tag>

</HStack>

        <Divider />
        <b>shacklefree.in/URL</b>
     
        <Flex >
          <Center h='0px'>
        
            <Link color="#4D5359" m={3} pt={3} align={'center'} textAlign={'center'} href="https://www.buymeacoffee.com/samair" isExternal> Help keep this alive!</Link>
            <Link color="#4D5359" m={3} pt={3} align={'center'} textAlign={'center'} href="/contact"> Found an issue?</Link>

          </Center>
        </Flex>
      </Stack>
      <Divider />
      
      <SupportedSites></SupportedSites>
      <ToastContainer />
    </Container>
  );
}

